<template>
	<div>
		<CCard>
			<CCardHeader>
				<h4 class="center-text">
					Reporte Clientes sin Widget
				</h4>
			</CCardHeader>
			<CCardBody>
				<div id="information" v-if="this.showContent === 'information'">
					<CRow>
						<CCol sm="12">
							<br />
							<p class="h6 justify-text">
								Este reporte muestra los clientes que aun no
								poseen un widget de <b>Instagram</b> en
								<b>Infoguia</b>. Para generar un reporte por
								favor ingresa en la siguiente casilla el nombre
								del ejecutivos de ventas.
							</p>
						</CCol>
					</CRow>
					<seller-autocomplete
						@submit="submitSeller"
						@zoneChecked="reportForZone"
						@generate="generateReport"
					/>
				</div>
				<div v-else-if="this.showContent === 'reportTable'">
					<table-report
						:data-post-report="dataForPost"
						:seller-name="sellerData.nombre_completo"
						name-for-export="Clientes Sin Widget"
						@goBack="resetReport"
					/>
				</div>
			</CCardBody>
		</CCard>
	</div>
</template>

<script>
	import sellerAutocomplete from '../../components/reports/sellerAutocomplete.vue';
	import TableReport from '../../components/reports/tableReport.vue';
	export default {
		components: { sellerAutocomplete, TableReport },
		name: 'ReportWithoutWidget',
		title: 'Info Feed | Infoguia',
		data() {
			return {
				showContent: 'information',
				sellerData: null,
				leaderZoneChecked: false,
				dismissSecs: 10,
				dismissCountDown: 0,
				dataForPost: null,
			};
		},
		created() {},
		methods: {
			submitSeller(result) {
				this.leaderZoneChecked = false;
				if (result !== undefined) {
					this.sellerData = result;
				}
			},
			reportForZone(value) {
				this.leaderZoneChecked = value;
			},
			generateReport() {
				this.dataForPost = {
					idSeller: this.sellerData.id,
					reportType: 'customersWithoutWidget',
					checkZoneSales: this.leaderZoneChecked,
				};
				this.showContent = 'reportTable';
			},
			resetReport() {
				this.showContent = 'information';
				this.sellerData = null;
				this.leaderZoneChecked = false;
				this.dataForPost = null;
			},
		},
	};
</script>
